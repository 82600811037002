<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
          <el-tab-pane label="规划项目库" name="first">
            <h2 style="margin-bottom: 10px; text-align: center">黑龙江省“十四五”铁路规划建设项目表 <span @click="wordDetail()" class="xzecl" >黑龙江省“十四五”铁路规划建设项目表</span> </h2>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <td :width="38">序号</td>
                    <td :width="323">项目名称</td>
                    <td :width="256">建设规模（公里）</td>
                    <td :width="188">&ldquo;总投资（亿元）</td>
                    <td :width="199">&ldquo;十四五&rdquo;投资（亿元）</td>
                    <td :width="139">备注</td>
                  </tr>
                </thead>
                <tbody>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="361">合计</td>
                    <td :width="256">4163</td>
                    <td :width="188">1795</td>
                    <td :width="199">1535</td>
                    <td :width="139"></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="361">一、续建项目</td>
                    <td :width="256">883</td>
                    <td :width="188">624</td>
                    <td :width="199">259</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">1</td>
                    <td :width="323">铁力至伊春客专</td>
                    <td :width="256">112</td>
                    <td :width="188">92</td>
                    <td :width="199">88</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">2</td>
                    <td :width="323">牡丹江至佳木斯客专</td>
                    <td :width="256">376</td>
                    <td :width="188">375</td>
                    <td :width="199">55</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">3</td>
                    <td :width="323">佳木斯至鹤岗铁路改造</td>
                    <td :width="256">72</td>
                    <td :width="188">31</td>
                    <td :width="199">16</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">4</td>
                    <td :width="323">北安（龙镇）至黑河铁路扩能</td>
                    <td :width="256">204</td>
                    <td :width="188">68</td>
                    <td :width="199">68</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">5</td>
                    <td :width="323">同江中俄铁路大桥</td>
                    <td :width="256">32</td>
                    <td :width="188">26</td>
                    <td :width="199">1</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">6</td>
                    <td :width="323">宝迎铁路宝清至朝阳段</td>
                    <td :width="256">38</td>
                    <td :width="188">8</td>
                    <td :width="199">8</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">7</td>
                    <td :width="323">大庆铁路专用线项目</td>
                    <td :width="256">26</td>
                    <td :width="188">14</td>
                    <td :width="199">14</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">8</td>
                    <td :width="323">绥化铁路专用线项目</td>
                    <td :width="256">11</td>
                    <td :width="188">3</td>
                    <td :width="199">3</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">9</td>
                    <td :width="323">牡丹江铁路专用线及陆港物流园区项目</td>
                    <td :width="256">11</td>
                    <td :width="188">8</td>
                    <td :width="199">8</td>
                    <td :width="139"></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="361">二、&ldquo;十四五&rdquo;新开工项目</td>
                    <td :width="256">1545</td>
                    <td :width="188">664</td>
                    <td :width="199">770</td>
                    <td :width="139"></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="361">（一）高速铁路</td>
                    <td :width="256">326</td>
                    <td :width="188">365</td>
                    <td :width="199">365</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">1</td>
                    <td :width="323">哈尔滨至绥化至铁力客专</td>
                    <td :width="256">206</td>
                    <td :width="188">208</td>
                    <td :width="199">208</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">2</td>
                    <td :width="323">牡丹江至敦化客专</td>
                    <td :width="256">120</td>
                    <td :width="188">157</td>
                    <td :width="199">157</td>
                    <td :width="139"></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="361">（二）普速铁路</td>
                    <td :width="256">1184</td>
                    <td :width="188">288</td>
                    <td :width="199">288</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">1</td>
                    <td :width="323">哈尔滨至绥化至北安至龙镇铁路改造</td>
                    <td :width="256">388</td>
                    <td :width="188">105</td>
                    <td :width="199">105</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">2</td>
                    <td :width="323">齐齐哈尔至富裕至加格达奇铁路改造</td>
                    <td :width="256">430</td>
                    <td :width="188">88</td>
                    <td :width="199">88</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">3</td>
                    <td :width="323">新建宝迎铁路二期朝阳至迎春段</td>
                    <td :width="256">40</td>
                    <td :width="188">13</td>
                    <td :width="199">13</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">4</td>
                    <td :width="323">佳木斯至同江（抚远）铁路改造</td>
                    <td :width="256">289</td>
                    <td :width="188">69</td>
                    <td :width="199">69</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">5</td>
                    <td :width="323">新建北安至五大连池铁路</td>
                    <td :width="256">37</td>
                    <td :width="188">13</td>
                    <td :width="199">13</td>
                    <td :width="139"></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="361">（三）铁路专用线</td>
                    <td :width="256">35</td>
                    <td :width="188">10</td>
                    <td :width="199">10</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">1</td>
                    <td :width="323">建设益海嘉里（富裕）铁路专用线</td>
                    <td :width="256">11</td>
                    <td :width="188">1</td>
                    <td :width="199">1</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">2</td>
                    <td :width="323">讷河集中供热铁路专用线</td>
                    <td :width="256">0.2</td>
                    <td :width="188">0.1</td>
                    <td :width="199">0.1</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">3</td>
                    <td :width="323">哈尔滨钢铁产业园铁路专用线</td>
                    <td :width="256">8</td>
                    <td :width="188">2</td>
                    <td :width="199">2</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">4</td>
                    <td :width="323">益海嘉里（密山）铁路专用线</td>
                    <td :width="256">2</td>
                    <td :width="188">0.3</td>
                    <td :width="199">0.3</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">5</td>
                    <td :width="323">松岭区铅锌钼矿等铁路专用线</td>
                    <td :width="256">1</td>
                    <td :width="188">0.3</td>
                    <td :width="199">0.3</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">6</td>
                    <td :width="323">甘南工业园区铁路专用线</td>
                    <td :width="256">14</td>
                    <td :width="188">6.9</td>
                    <td :width="199">6.9</td>
                    <td :width="139"></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="361">（四）普速铁路安全整治铁路道口平改立工程</td>
                    <td :width="256">改造道口380个</td>
                    <td :width="188">106.0</td>
                    <td :width="199">106</td>
                    <td :width="139"></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="361">三、&ldquo;十四五&rdquo;谋划项目</td>
                    <td :width="256">1735</td>
                    <td :width="188">507</td>
                    <td :width="199">507</td>
                    <td :width="139"></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="361">（一）高速铁路</td>
                    <td :width="256">180</td>
                    <td :width="188">185</td>
                    <td :width="199">185</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">1</td>
                    <td :width="323">齐齐哈尔至通辽客专</td>
                    <td :width="256">60</td>
                    <td :width="188">62</td>
                    <td :width="199">62</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">2</td>
                    <td :width="323">齐齐哈尔至满洲里客专</td>
                    <td :width="256">120</td>
                    <td :width="188">123</td>
                    <td :width="199">123</td>
                    <td :width="139"></td>
                  </tr>
                  <tr style="font-weight: bold">
                    <td colspan="2" :width="361">（二）普速铁路</td>
                    <td :width="256">1555</td>
                    <td :width="188">322</td>
                    <td :width="199">322</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">1</td>
                    <td :width="323">漠河至满归（古莲）铁路</td>
                    <td :width="256">116</td>
                    <td :width="188">37</td>
                    <td :width="199">37</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">2</td>
                    <td :width="323">既有哈佳线绥化至铁力至佳木斯电化扩能</td>
                    <td :width="256">380</td>
                    <td :width="188">87</td>
                    <td :width="199">87</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">3</td>
                    <td :width="323">新建黑河至乌伊岭铁路</td>
                    <td :width="256">364</td>
                    <td :width="188">78</td>
                    <td :width="199">78</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">4</td>
                    <td :width="323">牡丹江至佳木斯铁路电气化改造工程（含勃七线、林密线林口至鸡西段）</td>
                    <td :width="256">463</td>
                    <td :width="188">68</td>
                    <td :width="199">68</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">5</td>
                    <td :width="323">拉滨铁路改造</td>
                    <td :width="256">112</td>
                    <td :width="188">16</td>
                    <td :width="199">16</td>
                    <td :width="139"></td>
                  </tr>
                  <tr>
                    <td :width="38">6</td>
                    <td :width="323">新建黑金至黑河铁路</td>
                    <td :width="256">120</td>
                    <td :width="188">35</td>
                    <td :width="199">35</td>
                    <td :width="139"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
    name: "Home",
    components: {},
    data() {
        return {
            activeName: "first",
        };
    },
    mounted() {},
    methods: {
        wordDetail() {
            // 微软提供的方法
            window.open(
                "http://zzgroup.hljzztech.com/黑龙江省“十四五”铁路规划建设项目表.xls"
            );
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
    },
    watch: {},
};
</script>
<style lang="less">
</style>
