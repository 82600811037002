<template>
    <div>
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="form-inline formBox">
                                    <el-form-item label="项目名称">
                                        <el-input style="width: 200px" size="medium" v-model="xmmc" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="项目年度">
                                        <el-select v-model="xmnd" placeholder="“十四五”规划" style="width: 200px">
                                            <el-option label="" checked value="">“十四五”规划</el-option>
                                            <el-option label="2021" value="2021">2021年度计划</el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="项目性质">
                                        <el-cascader size="medium" style="width: 200px" v-model="checkedXmxz" :options="xmxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目类型">
                                        <el-cascader size="medium" style="width: 200px" v-model="checkedXmlx" :options="xmlxList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-button type="primary" style="margin-top:19px" size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                    <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">清空</el-button>
                                </el-form>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">
                                <span class="fright">
                                    <el-button @click="exportData" type="primary" size="mini" round icon="el-icon-upload2">导出</el-button>
                                    <el-button @click="baobiao = true" type="primary2" size="mini" round icon="el-icon-share">报表</el-button>
                                    <el-button @click="drawer = true;getTotal();" type="primary" size="mini" round icon="el-icon-s-data">汇总</el-button>
                                </span>
                                <h3 class="tit">铁路列表</h3>
                            </div>
                            <div class="box-content">
                                <div class="tj">
                                    <div class="total-item total-item-1">
                                        <img src="../../assets/icon/tj1.png" alt="">
                                        <div class="t">
                                            <h5>项目数量<small>（个）</small></h5>
                                            <b class="date-num">{{ total }}</b>
                                        </div>
                                    </div>
                                    <div class="total-item total-item-2">
                                        <img src="../../assets/icon/tj2.png" alt="">
                                        <div class="t">
                                            <h5>建设规模<small>（公里）</small></h5>
                                            <b class="date-num">{{ jsgm.toFixed(1) }}</b>
                                        </div>
                                    </div>

                                    <div class="total-item total-item-5">
                                        <img src="../../assets/icon/tj5.png" alt="">
                                        <div class="t">
                                            <h5>十四五投资<small>（亿元）</small></h5>
                                            <b class="date-num">{{ sswtz.toFixed(1) }}</b>
                                        </div>
                                    </div>
                                </div>
                                <el-table @sort-change="changeSort" :data="tableData" size="small " :height="tableHeight" border :default-sort="{ prop: 'XH', order: 'ascending' }" style="width: 100%">
                                    <el-table-column prop="XMMC" label="项目名称" show-overflow-tooltip :width="350" sortable></el-table-column>
                                    <el-table-column prop="XMXZ" label="项目性质" :width="190" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="XMLX" label="项目类型" :width="190" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="JSGM" label="建设规模（公里）" :width="190" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="SSWTZ" label="“十四五”投资（亿元）" sortable show-overflow-tooltip></el-table-column>
                                </el-table>
                                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                                </el-pagination>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <el-drawer title="汇总" :visible.sync="drawer" :direction="direction" size="650px">
            <Hz :totalXmxz="totalXmxz" :totalJsxz="totalJsxz" :totalXmlx="totalXmlx" />
        </el-drawer>
        <el-drawer title="报表" :visible.sync="baobiao" :direction="'rtl'" size="100%">
            <BbRailway v-if="baobiao" />
        </el-drawer>
    </div>
</template>
<script>
import Hz from "@/components/Query/Railway/Hz.vue"; //汇总
import { exportData } from "../../js/util/export.js"; //导出表格
import BbRailway from "../Query/BbRailways.vue";
export default {
    name: "Home",
    components: {
        Hz,
        BbRailway,
    },
    data() {
        return {
            sortData: {
                field: "XH",
                sort: "asc",
            },
            tableHeight: 0, //表格高度
            activeIndex: true, //显隐高级搜索
            baobiao: false,
            /**查询条件 begin*/
            xmmc: "", //项目名称
            xmnd: "", //项目年度
            checkedXmxz: [], //项目性质
            checkedJsxz: [], //建设性质
            checkedXmlx: [], //项目类型
            /**查询条件 end*/

            xmxzShow: false, //项目性质查询条件显隐
            jsxzShow: true, //建设性质查询条件显隐
            xmlxShow: false, //项目类型查询条件显隐
            xmxzList: [
                {
                    value: "续建项目",
                    label: "续建项目",
                    disabled: false,
                },
                {
                    value: "“十四五”新开工项目",
                    label: "“十四五”新开工项目",
                    disabled: false,
                },
                {
                    value: "“十四五”谋划项目",
                    label: "“十四五”谋划项目",
                    disabled: false,
                },
            ],
            xmlxList: [
                {
                    value: "高速铁路",
                    label: "高速铁路",
                    disabled: false,
                },
                {
                    value: "普速铁路",
                    label: "普速铁路",
                    disabled: false,
                },
                {
                    value: "铁路专用线",
                    label: "铁路专用线",
                    disabled: false,
                },
                {
                    value: "普速铁路安全整治铁路道口平改立工程",
                    label: "普速铁路安全整治铁路道口平改立工程",
                    disabled: false,
                },
                {
                    value: "无",
                    label: "无",
                    disabled: false,
                },
            ],
            currentPage: 1, //当前页
            pageSize: 30, //每页记录数
            isIndeterminateXmxz: true,
            isIndeterminateJsxz: true,
            isIndeterminateXmlx: true,
            drawer: false,
            direction: "rtl",
            tableData: [], //请求列表
            total: 0, //请求记录数
            jsgm: 0,
            sswtz: 0,
            totalXmxz: [], //汇总按项目性质
            totalXmlx: [], //汇总按项目类型
            totalJsxz: [], //汇总按建设性质
            totalXzqh: [], //汇总按行政区划
        };
    },
    mounted() {
        var that = this;
        let height = this.$refs.init.offsetHeight;
        setTimeout(() => {
            that.tableHeight = document.body.offsetHeight - height - 265;
        }, 100);
        this.search();
    },
    methods: {
        changeSort(val) {
            if (val.order) {
                this.sortData = {
                    field: val.prop,
                    sort: val.order == "ascending" ? "asc" : "desc",
                };
            } else {
                this.sortData = {
                    field: "XMXH",
                    sort: "asc",
                };
            }
            this.tableData = [];
            this.search();
        },
        exportData() {
            exportData("Plan_railway", this.postData());
        },
        //查询
        search() {
            this.getPageTotal(); //查汇总
            this.http
                .post(
                    "/api/Plan_railway/GetPageData",
                    this.postData(),
                    "正在请求数据，请稍候..."
                )
                .then((res) => {
                    if (res.status == 0) {
                        this.total = res.total;
                        this.tableData = res.rows;
                    }
                });
        },
        //汇总
        getPageTotal() {
            this.http
                .post("/api/Plan_railway/GetPageTotal", this.postData())
                .then((res) => {
                    // console.log(res);
                    this.jsgm = res.jsgm;
                    this.sswtz = res.sswtz;
                });
        },
        postData() {
            //项目名称
            var query_xmmc = {
                Name: "XMMC",
                Value: this.xmmc,
                DisplayType: "like",
            };
            //项目年度
            var query_xmnd = {
                Name: "XMND",
                Value: this.xmnd,
                DisplayType: "Equal",
            };

            //项目性质
            var query_xmxz = {
                Name: "XMXZ",
                Value: this.checkedXmxz.join(","),
                DisplayType: "checkbox",
            };

            //建设性质
            var query_jsxz = {
                Name: "JSXZ",
                Value: this.checkedJsxz.join(","),
                DisplayType: "checkbox",
            };

            //项目类型
            var query_xmlx = {
                Name: "XMLX",
                Value: this.checkedXmlx.join(","),
                DisplayType: "checkbox",
            };
            console.log(query_xmlx);
            var postData = {
                page: this.currentPage,
                rows: this.pageSize,
                Sort: this.sortData.field,
                Order: this.sortData.sort,
                wheres: JSON.stringify([
                    query_xmmc,
                    query_xmnd,
                    query_xmxz,
                    query_jsxz,
                    query_xmlx,
                ]),
            };
            return postData;
        },
        clearCondition() {
            this.xmmc = "";
            this.xmnd = "";
            this.qqgzjz = [];
            this.gllx = "";
            this.checkedXmxz = [];
            this.checkedJsxz = [];
            this.checkedXmlx = [];
        },
        getTotal() {
            //汇总
            this.http
                .post("/api/Plan_railway/GetTotal", this.postData())
                .then((total) => {
                    this.totalJsxz = total.jsxz;
                    this.totalXmxz = total.xmxz;
                    this.totalXmlx = total.xmlx;
                });
        },

        handleSizeChange(val) {
            this.pageSize = val;
            this.search();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.search();
        },
    },
    watch: {},
};
</script>
